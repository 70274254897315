var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-10 my-3", attrs: { fluid: "" } },
    [
      _c(
        "portal",
        { attrs: { to: "page-title" } },
        [
          _c("v-breadcrumbs", {
            attrs: {
              items: [
                { text: _vm.$tc("labels.general", 2), disabled: false },
                { text: _vm.$t("labels.shiftTypes"), disabled: false }
              ]
            },
            scopedSlots: _vm._u([
              {
                key: "divider",
                fn: function() {
                  return [
                    _c("v-icon", { attrs: { size: "8" } }, [
                      _vm._v("\n          far fa-chevron-right\n        ")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { id: "shiftTypes" } },
        [
          _c(
            "v-row",
            { staticClass: "actions", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    staticClass:
                      "name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block",
                    attrs: {
                      "append-icon": _vm.filters.name ? "" : "fal fa-search",
                      clearable: !!_vm.filters.name,
                      dense: "",
                      "hide-details": "",
                      placeholder: _vm.$t("labels.searchByName"),
                      solo: ""
                    },
                    model: {
                      value: _vm.filters.name,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.filters,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "filters.name"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right mt-2 mr-4",
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.openShiftTypeDialog()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.addShiftType")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-data-table", {
            directives: [
              {
                name: "resize",
                rawName: "v-resize.quiet",
                value: _vm.onWindowResized,
                expression: "onWindowResized",
                modifiers: { quiet: true }
              }
            ],
            attrs: {
              "fixed-header": "",
              headers: _vm.headers,
              "header-props": { sortIcon: "fa fa-arrow-up" },
              "hide-default-footer": "",
              items: _vm.paginatedShiftTypes,
              "items-per-page": _vm.pagination.itemsPerPage,
              "must-sort": "",
              "single-select": "",
              "sort-by": _vm.sort.by,
              "sort-desc": _vm.sort.desc
            },
            on: {
              "update:sortBy": function($event) {
                return _vm.$set(_vm.sort, "by", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.sort, "by", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.sort, "desc", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.sort, "desc", $event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "header.description",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      )
                    ])
                  ]
                }
              },
              {
                key: "header.webAppDisplay",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      )
                    ])
                  ]
                }
              },
              {
                key: "header.mobileAppDisplay",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c("span", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(
                        "\n          " + _vm._s(header.text) + "\n        "
                      )
                    ])
                  ]
                }
              },
              {
                key: "item",
                fn: function(ref) {
                  var item = ref.item
                  var tableHeaders = ref.headers
                  return [
                    _c(
                      "tr",
                      {
                        class: item.obsolete ? "obsolete" : "",
                        on: {
                          mouseenter: function($event) {
                            _vm.hoveredItem = item
                          },
                          mouseleave: function($event) {
                            _vm.hoveredItem = null
                          }
                        }
                      },
                      [
                        _vm._l(tableHeaders, function(header) {
                          return _c(
                            "td",
                            { key: header.value, staticClass: "text-start" },
                            [
                              header.value === "name"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: { title: item.name }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.name) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "description"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: { title: item.description }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.description) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "webAppDisplay"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: {
                                        title: _vm.getAppDisplay(item, "web")
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getAppDisplay(item, "web")
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "mobileAppDisplay"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: {
                                        title: _vm.getAppDisplay(item, "mobile")
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getAppDisplay(item, "mobile")
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "startTime"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: { title: item.startTime }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.formatTime(item.startTime)
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "endTime"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: { title: item.endTime }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.formatTime(item.endTime)) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "maxShiftCount"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-3 text-truncate d-block",
                                      style: { width: header.width + "px" },
                                      attrs: { title: item.maxShiftCount }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.maxShiftCount) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              header.value === "partakeInScheduling"
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "d-inline-block",
                                        style: { "min-width": "140px" }
                                      },
                                      [
                                        item.partakeInScheduling
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "success",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fas fa-check-circle\n                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "grey",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  far fa-times-circle\n                "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              header.value === "onCall"
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "d-inline-block",
                                        style: { "min-width": "140px" }
                                      },
                                      [
                                        item.onCall
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "success",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fas fa-check-circle\n                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "grey",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  far fa-times-circle\n                "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        }),
                        _c(
                          "v-speed-dial",
                          {
                            staticClass: "mt-6 shift-type-dial",
                            attrs: {
                              absolute: "",
                              value:
                                _vm.hoveredItem &&
                                _vm.hoveredItem.id === item.id,
                              right: "",
                              direction: "left",
                              transition: "slide-x-reverse-transition"
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "edit",
                                attrs: {
                                  fab: "",
                                  height: "30",
                                  width: "30",
                                  title: _vm.$t("labels.editShiftType")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openShiftTypeDialog(item)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(
                                    "\n                fal fa-pencil\n              "
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "delete",
                                attrs: {
                                  fab: "",
                                  height: "30",
                                  width: "30",
                                  title: _vm.$t("labels.deleteShiftType")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openDeleteConfirmationDialog(
                                      item
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "error", small: "" } },
                                  [
                                    _vm._v(
                                      "\n                fal fa-trash-alt\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c("v-pagination", {
                      staticClass: "py-4 footer",
                      attrs: {
                        color: "secondary",
                        length: _vm.numberOfPages,
                        "next-icon": "far fa-chevron-right",
                        "prev-icon": "far fa-chevron-left",
                        "total-visible": _vm.pagination.maxPaginationControls
                      },
                      model: {
                        value: _vm.pagination.currentPage,
                        callback: function($$v) {
                          _vm.$set(_vm.pagination, "currentPage", $$v)
                        },
                        expression: "pagination.currentPage"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm.showShiftTypeDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                persistent: "",
                width: "540px",
                value: _vm.showShiftTypeDialog
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-0",
                  staticStyle: { "overflow-x": "hidden" },
                  attrs: { id: "shiftType" }
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "body-2 d-block mb-2" },
                    [
                      _c("span", { staticClass: "body-2 font-weight-medium" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.selectedShiftType.id
                                ? _vm.$t("labels.editShiftType")
                                : _vm.$t("labels.addShiftType")
                            ) +
                            "\n        "
                        )
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "float-right mt-n1",
                          attrs: { disabled: _vm.saving, icon: "", small: "" },
                          on: { click: _vm.closeShiftTypeDialog }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("\n            fal fa-times\n          ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0" },
                    [
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var invalid = ref.invalid
                                var passes = ref.passes
                                return [
                                  _c(
                                    "v-form",
                                    { staticClass: "shift-type" },
                                    [
                                      _c(
                                        "v-container",
                                        { staticClass: "py-0 px-6" },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("VeeTextField", {
                                                    attrs: {
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "labels.name"
                                                      ),
                                                      name: "name",
                                                      outlined: "",
                                                      rules: {
                                                        required: true,
                                                        max: 32,
                                                        excluded: _vm.namesInUse
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType,
                                                          "name",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.name"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("VeeTextField", {
                                                    attrs: {
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "labels.description"
                                                      ),
                                                      name: "description",
                                                      outlined: "",
                                                      rules: "max:256"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .description,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType,
                                                          "description",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.description"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("VeeSelect", {
                                                    attrs: {
                                                      dense: "",
                                                      items: _vm.startTimes,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "labels.startTime"
                                                      ),
                                                      outlined: "",
                                                      rules: "required"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .startTime,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType,
                                                          "startTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.startTime"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("VeeSelect", {
                                                    attrs: {
                                                      dense: "",
                                                      items: _vm.endTimes,
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      label: _vm.$t(
                                                        "labels.endTime"
                                                      ),
                                                      outlined: "",
                                                      rules: "required"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .endTime,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType,
                                                          "endTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.endTime"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("VeeSelect", {
                                                    attrs: {
                                                      dense: "",
                                                      items:
                                                        _vm.displayOptions.web,
                                                      label: _vm.$t(
                                                        "labels.webAppDisplay"
                                                      ),
                                                      outlined: "",
                                                      rules: "required"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .styles.web
                                                          .symbolType,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType
                                                            .styles.web,
                                                          "symbolType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.styles.web.symbolType"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("VeeTextField", {
                                                    attrs: {
                                                      dense: "",
                                                      disabled:
                                                        _vm.selectedShiftType
                                                          .styles.web
                                                          .symbolType !==
                                                        "text",
                                                      label: _vm.$t(
                                                        "labels.webShortcode"
                                                      ),
                                                      name: "webShortcode",
                                                      maxlength: "3",
                                                      outlined: "",
                                                      rules: {
                                                        required:
                                                          _vm.selectedShiftType
                                                            .styles.web
                                                            .symbolType ===
                                                          "text",
                                                        alpha_dash: true,
                                                        max: 3
                                                      },
                                                      title: _vm.$t(
                                                        "labels.webShortcode"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .styles.web
                                                          .symbolValue,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType
                                                            .styles.web,
                                                          "symbolValue",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.styles.web.symbolValue"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "3" } },
                                                [
                                                  _c("VeeTextField", {
                                                    attrs: {
                                                      dense: "",
                                                      label: _vm.$t(
                                                        "labels.shortcut"
                                                      ),
                                                      maxlength: "1",
                                                      name: "shortcut",
                                                      outlined: "",
                                                      rules: {
                                                        alpha_lower: true,
                                                        max: 1,
                                                        excluded:
                                                          _vm.shortcutsInUse
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .styles.web
                                                          .keyboardShortcut,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType
                                                            .styles.web,
                                                          "keyboardShortcut",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.styles.web.keyboardShortcut"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("VeeSelect", {
                                                    attrs: {
                                                      dense: "",
                                                      items:
                                                        _vm.displayOptions
                                                          .mobile,
                                                      label: _vm.$t(
                                                        "labels.mobileAppDisplay"
                                                      ),
                                                      outlined: "",
                                                      rules: "required"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .styles.mobile
                                                          .symbolType,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType
                                                            .styles.mobile,
                                                          "symbolType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.styles.mobile.symbolType"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("VeeTextField", {
                                                    attrs: {
                                                      dense: "",
                                                      disabled:
                                                        _vm.selectedShiftType
                                                          .styles.mobile
                                                          .symbolType !==
                                                        "text",
                                                      label: _vm.$t(
                                                        "labels.mobileShortcode"
                                                      ),
                                                      maxlength: "3",
                                                      name: "mobileShortcode",
                                                      outlined: "",
                                                      rules: {
                                                        required:
                                                          _vm.selectedShiftType
                                                            .styles.mobile
                                                            .symbolType ===
                                                          "text",
                                                        alpha_dash: true,
                                                        max: 3
                                                      },
                                                      title: _vm.$t(
                                                        "labels.mobileShortcode"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .styles.mobile
                                                          .symbolValue,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType
                                                            .styles.mobile,
                                                          "symbolValue",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.styles.mobile.symbolValue"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "pt-0 mt-1 d-inline-block",
                                                    attrs: {
                                                      color: "success",
                                                      dense: "",
                                                      "hide-details": "",
                                                      inset: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-4 body-2 grey--text text--darken-3",
                                                                  attrs: {
                                                                    title: _vm.$t(
                                                                      "labels.involveInScheduling"
                                                                    )
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.involveInScheduling"
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .partakeInScheduling,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType,
                                                          "partakeInScheduling",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.partakeInScheduling"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-switch", {
                                                    staticClass:
                                                      "pt-0 mt-1 d-inline-block",
                                                    attrs: {
                                                      color: "success",
                                                      dense: "",
                                                      disabled: !_vm
                                                        .selectedShiftType
                                                        .partakeInScheduling,
                                                      inset: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-4 body-2 grey--text text--darken-3",
                                                                  attrs: {
                                                                    title: _vm.$t(
                                                                      "labels.isOnCall"
                                                                    )
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.isOnCall"
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectedShiftType
                                                          .onCall,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedShiftType,
                                                          "onCall",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedShiftType.onCall"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-4",
                                              attrs: {
                                                dense: "",
                                                "no-gutters": ""
                                              }
                                            },
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-3 body-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.selfScheduleRules"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grey--text caption"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.selfScheduleRulesHint"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "grey lighten-4 self-schedule-rules mb-4",
                                              attrs: { elevation: 0 }
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "px-4 pt-4",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "9" } },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "grey--text text--darken-3 body-2 label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.maxShiftRule"
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "text-right",
                                                      attrs: { cols: "3" }
                                                    },
                                                    [
                                                      _c("VeeTextField", {
                                                        attrs: {
                                                          dense: "",
                                                          maxlength: "3",
                                                          name: "maxShiftCount",
                                                          outlined: "",
                                                          rules:
                                                            "numeric|min_value:1|max_value:" +
                                                            _vm.MAX_SHIFT_COUNT
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.validateMaxShiftCount
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .selectedShiftType
                                                              .maxShiftCount,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.selectedShiftType,
                                                              "maxShiftCount",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "selectedShiftType.maxShiftCount"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-right pb-0 pt-4 px-8",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-4",
                                                  attrs: {
                                                    disabled: _vm.saving,
                                                    text: ""
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.closeShiftTypeDialog
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t("labels.cancel")
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              ),
                                              _vm.selectedShiftType.id
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "submit",
                                                      attrs: {
                                                        color: "secondary",
                                                        disabled:
                                                          invalid || _vm.saving
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return passes(
                                                            _vm.update
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.saving
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary lighten-2",
                                                                indeterminate:
                                                                  "",
                                                                size: "22",
                                                                width: "2"
                                                              }
                                                            }
                                                          )
                                                        : _c("span", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.save"
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "submit",
                                                      attrs: {
                                                        color: "secondary",
                                                        disabled:
                                                          invalid || _vm.saving
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return passes(_vm.add)
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.saving
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary lighten-2",
                                                                indeterminate:
                                                                  "",
                                                                size: "22",
                                                                width: "2"
                                                              }
                                                            }
                                                          )
                                                        : _c("span", [
                                                            _vm._v(
                                                              "\n                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.add"
                                                                  )
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ])
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2767109848
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.deleteDialog.show
        ? _c("DeleteConfirmation", {
            attrs: {
              action: "org/checkShiftTypeDependencies",
              "delete-body": _vm.$t("descriptions.continueDeleteShiftType", {
                name: _vm.deleteDialog.shiftType.name
              }),
              "dependencies-body": _vm.$t(
                "descriptions.hasDependenciesShiftType",
                { name: _vm.deleteDialog.shiftType.name }
              ),
              item: _vm.deleteDialog.shiftType,
              title: _vm.$t("labels.deleteShiftType?")
            },
            on: {
              close: _vm.closeDeleteConfirmationDialog,
              del: _vm.deleteShiftType
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }