<template>
  <v-container
    class="px-10 my-3"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.general', 2), disabled: false }, { text: $t('labels.shiftTypes'), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      id="shiftTypes"
    >
      <v-row
        class="actions"
        no-gutters
      >
        <v-col>
          <v-text-field
            v-model.trim="filters.name"
            :append-icon="filters.name ? '' : 'fal fa-search'"
            class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
            :clearable="!!filters.name"
            dense
            hide-details
            :placeholder="$t('labels.searchByName')"
            solo
          />
          <v-btn
            class="float-right mt-2 mr-4"
            color="secondary"
            @click="openShiftTypeDialog()"
          >
            {{ $t('labels.addShiftType') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-resize.quiet="onWindowResized"
        fixed-header
        :headers="headers"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="paginatedShiftTypes"
        :items-per-page="pagination.itemsPerPage"
        must-sort
        single-select
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
      >
        <template #header.description="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #header.webAppDisplay="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #header.mobileAppDisplay="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #item="{ item, headers: tableHeaders }">
          <tr
            :class="item.obsolete ? 'obsolete' : ''"
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
          >
            <td
              v-for="header in tableHeaders"
              :key="header.value"
              class="text-start"
            >
              <span
                v-if="header.value === 'name'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.name"
              >
                {{ item.name }}
              </span>
              <span
                v-if="header.value === 'description'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.description"
              >
                {{ item.description }}
              </span>
              <span
                v-if="header.value === 'webAppDisplay'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="getAppDisplay(item, 'web')"
              >
                {{ getAppDisplay(item, 'web') }}
              </span>
              <span
                v-if="header.value === 'mobileAppDisplay'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="getAppDisplay(item, 'mobile')"
              >
                {{ getAppDisplay(item, 'mobile') }}
              </span>
              <span
                v-if="header.value === 'startTime'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.startTime"
              >
                {{ formatTime(item.startTime) }}
              </span>
              <span
                v-if="header.value === 'endTime'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.endTime"
              >
                {{ formatTime(item.endTime) }}
              </span>
              <span
                v-if="header.value === 'maxShiftCount'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.maxShiftCount"
              >
                {{ item.maxShiftCount }}
              </span>
              <template v-if="header.value === 'partakeInScheduling'">
                <span
                  class="d-inline-block"
                  :style="{ 'min-width': '140px' }"
                >
                  <v-icon
                    v-if="item.partakeInScheduling"
                    color="success"
                    small
                  >
                    fas fa-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    small
                  >
                    far fa-times-circle
                  </v-icon>
                </span>
              </template>
              <template v-if="header.value === 'onCall'">
                <span
                  class="d-inline-block"
                  :style="{ 'min-width': '140px' }"
                >
                  <v-icon
                    v-if="item.onCall"
                    color="success"
                    small
                  >
                    fas fa-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    small
                  >
                    far fa-times-circle
                  </v-icon>
                </span>
              </template>
            </td>
            <v-speed-dial
              absolute
              class="mt-6 shift-type-dial"
              :value="hoveredItem && hoveredItem.id === item.id"
              right
              :direction="'left'"
              :transition="'slide-x-reverse-transition'"
            >
              <v-btn
                class="edit"
                fab
                height="30"
                width="30"
                :title="$t('labels.editShiftType')"
                @click="openShiftTypeDialog(item)"
              >
                <v-icon small>
                  fal fa-pencil
                </v-icon>
              </v-btn>
              <v-btn
                class="delete"
                fab
                height="30"
                width="30"
                :title="$t('labels.deleteShiftType')"
                @click="openDeleteConfirmationDialog(item)"
              >
                <v-icon
                  color="error"
                  small
                >
                  fal fa-trash-alt
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </tr>
        </template>
        <template #footer>
          <v-pagination
            v-model="pagination.currentPage"
            class="py-4 footer"
            color="secondary"
            :length="numberOfPages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="pagination.maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="showShiftTypeDialog"
      persistent
      width="540px"
      :value="showShiftTypeDialog"
    >
      <v-card
        id="shiftType"
        class="pa-0"
        style="overflow-x: hidden;"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ selectedShiftType.id ? $t('labels.editShiftType') : $t('labels.addShiftType') }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeShiftTypeDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form class="shift-type">
              <v-container class="py-0 px-6">
                <v-row dense>
                  <v-col
                    cols="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedShiftType.name"
                      dense
                      :label="$t('labels.name')"
                      name="name"
                      outlined
                      :rules="{ required: true, max: 32, excluded: namesInUse }"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedShiftType.description"
                      dense
                      :label="$t('labels.description')"
                      name="description"
                      outlined
                      rules="max:256"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="6"
                  >
                    <VeeSelect
                      v-model="selectedShiftType.startTime"
                      dense
                      :items="startTimes"
                      item-text="name"
                      item-value="id"
                      :label="$t('labels.startTime')"
                      outlined
                      rules="required"
                    />
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <VeeSelect
                      v-model="selectedShiftType.endTime"
                      dense
                      :items="endTimes"
                      item-text="name"
                      item-value="id"
                      :label="$t('labels.endTime')"
                      outlined
                      rules="required"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <VeeSelect
                      v-model="selectedShiftType.styles.web.symbolType"
                      dense
                      :items="displayOptions.web"
                      :label="$t('labels.webAppDisplay')"
                      outlined
                      rules="required"
                    />
                  </v-col>
                  <v-col cols="3">
                    <VeeTextField
                      v-model.trim="selectedShiftType.styles.web.symbolValue"
                      dense
                      :disabled="selectedShiftType.styles.web.symbolType !== 'text'"
                      :label="$t('labels.webShortcode')"
                      name="webShortcode"
                      maxlength="3"
                      outlined
                      :rules="{ required: selectedShiftType.styles.web.symbolType === 'text', alpha_dash: true, max: 3 }"
                      :title="$t('labels.webShortcode')"
                    />
                  </v-col>
                  <v-col cols="3">
                    <VeeTextField
                      v-model.trim="selectedShiftType.styles.web.keyboardShortcut"
                      dense
                      :label="$t('labels.shortcut')"
                      maxlength="1"
                      name="shortcut"
                      outlined
                      :rules="{ alpha_lower: true, max: 1, excluded: shortcutsInUse }"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <VeeSelect
                      v-model="selectedShiftType.styles.mobile.symbolType"
                      dense
                      :items="displayOptions.mobile"
                      :label="$t('labels.mobileAppDisplay')"
                      outlined
                      rules="required"
                    />
                  </v-col>
                  <v-col cols="6">
                    <VeeTextField
                      v-model.trim="selectedShiftType.styles.mobile.symbolValue"
                      dense
                      :disabled="selectedShiftType.styles.mobile.symbolType !== 'text'"
                      :label="$t('labels.mobileShortcode')"
                      maxlength="3"
                      name="mobileShortcode"
                      outlined
                      :rules="{ required: selectedShiftType.styles.mobile.symbolType === 'text', alpha_dash: true, max: 3 }"
                      :title="$t('labels.mobileShortcode')"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6">
                    <v-switch
                      v-model="selectedShiftType.partakeInScheduling"
                      class="pt-0 mt-1 d-inline-block"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.involveInScheduling')"
                        >
                          {{ $t('labels.involveInScheduling') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-switch
                      v-model="selectedShiftType.onCall"
                      class="pt-0 mt-1 d-inline-block"
                      color="success"
                      dense
                      :disabled="!selectedShiftType.partakeInScheduling"
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.isOnCall')"
                        >
                          {{ $t('labels.isOnCall') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row
                  class="mb-4"
                  dense
                  no-gutters
                >
                  <v-col>
                    <div class="grey--text text--darken-3 body-2">
                      {{ $t('labels.selfScheduleRules') }}
                    </div>
                    <div class="grey--text caption">
                      {{ $t('labels.selfScheduleRulesHint') }}
                    </div>
                  </v-col>
                </v-row>
                <v-card
                  class="grey lighten-4 self-schedule-rules mb-4"
                  :elevation="0"
                >
                  <v-row
                    class="px-4 pt-4"
                    dense
                  >
                    <v-col cols="9">
                      <div class="grey--text text--darken-3 body-2 label">
                        {{ $t('labels.maxShiftRule') }}
                      </div>
                    </v-col>
                    <v-col
                      class="text-right"
                      cols="3"
                    >
                      <VeeTextField
                        v-model.number="selectedShiftType.maxShiftCount"
                        dense
                        maxlength="3"
                        name="maxShiftCount"
                        outlined
                        :rules="`numeric|min_value:1|max_value:${MAX_SHIFT_COUNT}`"
                        @input="validateMaxShiftCount"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>
              <v-divider />
              <v-row dense>
                <v-col
                  class="text-right pb-0 pt-4 px-8"
                  cols="12"
                >
                  <v-btn
                    class="mr-4"
                    :disabled="saving"
                    text
                    @click="closeShiftTypeDialog"
                  >
                    {{ $t('labels.cancel') }}
                  </v-btn>
                  <v-btn
                    v-if="selectedShiftType.id"
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(update)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.save') }}
                    </span>
                  </v-btn>
                  <v-btn
                    v-else
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(add)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.add') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DeleteConfirmation
      v-if="deleteDialog.show"
      action="org/checkShiftTypeDependencies"
      :delete-body="$t('descriptions.continueDeleteShiftType', { name: deleteDialog.shiftType.name })"
      :dependencies-body="$t('descriptions.hasDependenciesShiftType', { name: deleteDialog.shiftType.name })"
      :item="deleteDialog.shiftType"
      :title="$t('labels.deleteShiftType?')"
      @close="closeDeleteConfirmationDialog"
      @del="deleteShiftType"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import DeleteConfirmation from '@/components/admin/DeleteConfirmation';
import { showStatus } from '@/plugins/vue-notification';
import { SHIFT_TIME_INTERVAL } from '@/views/scheduling/constants';
import { ERROR_CODES } from '@/services/constants';

export default {
  components: {
    DeleteConfirmation,
    VeeSelect,
    VeeTextField
  },
  data () {
    const MAX_SHIFT_COUNT = _.get(
      this.$store.state.org,
      'settings.scheduling.period',
      42
    );
    return {
      MAX_SHIFT_COUNT,
      deleteDialog: {
        show: false,
        shiftType: null
      },
      displayOptions: {
        mobile: [
          { text: this.$t('labels.shortcode'), value: 'text' }
        ],
        web: [
          { text: this.$t('labels.startAndEndTime'), value: 'time' },
          { text: this.$t('labels.shortcode'), value: 'text' }
        ]
      },
      filters: {
        mobileAppDisplay: [],
        name: '',
        webAppDisplay: []
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        maxPaginationControls: 7
      },
      saving: false,
      selectedShiftType: null,
      showShiftTypeDialog: false,
      sort: {
        by: ['name'],
        desc: [false]
      }
    };
  },
  computed: {
    endTimes () {
      if (!this.selectedShiftType) {
        return [];
      }
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.selectedShiftType.startTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    filteredShiftTypes () {
      const filters = [];

      if (this.filters.name) {
        filters.push((shiftType) => shiftType.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) >= 0);
      }

      if (this.filters.webAppDisplay.length > 0) {
        const webAppDisplay = this.filters.webAppDisplay.reduce((shiftIds, filter) => {
          shiftIds.push(...filter.split(',').map((id) => parseInt(id)));
          return shiftIds;
        }, []);
        filters.push((shiftType) => webAppDisplay.includes(shiftType.id));
      }

      if (this.filters.mobileAppDisplay.length > 0) {
        const mobileAppDisplay = this.filters.mobileAppDisplay.reduce((shiftIds, filter) => {
          shiftIds.push(...filter.split(',').map((id) => parseInt(id)));
          return shiftIds;
        }, []);
        filters.push((shiftType) => mobileAppDisplay.includes(shiftType.id));
      }

      const orders = this.sort.desc.map((desc) => desc ? 'desc' : 'asc');
      let shiftTypes = this.shiftTypes;
      if (filters.length > 0) {
        shiftTypes = _.filter(this.shiftTypes, (shiftType) => filters.reduce((matches, filter) => {
          matches &= filter(shiftType);
          return matches;
        }, true));
      }

      return _.orderBy(shiftTypes, this.sort.by, orders);
    },
    headers () {
      return [
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: 118
        },
        {
          sortable: false,
          text: this.$t('labels.description'),
          value: 'description',
          width: 232
        },
        {
          sortable: false,
          text: this.$t('labels.webAppDisplay'),
          value: 'webAppDisplay',
          width: 142
        },
        {
          sortable: false,
          text: this.$t('labels.mobileAppDisplay'),
          value: 'mobileAppDisplay',
          width: 155
        },
        {
          sortable: true,
          text: this.$t('labels.startTime'),
          value: 'startTime',
          width: this.filteredShiftTypes.length > 0 ? 85 : 105
        },
        {
          sortable: true,
          text: this.$t('labels.endTime'),
          value: 'endTime',
          width: this.filteredShiftTypes.length > 0 ? 85 : 105
        },
        {
          sortable: true,
          text: this.$t('labels.maxShiftCountShort'),
          value: 'maxShiftCount',
          width: 170
        },
        {
          sortable: true,
          text: this.$t('labels.involveInScheduling'),
          value: 'partakeInScheduling',
          width: 155
        },
        {
          sortable: true,
          text: this.$t('labels.isOnCall'),
          value: 'onCall',
          width: '*'
        }
      ];
    },
    jobStatus () {
      return _.sortBy(this.$store.state.org.jobStatus, ['name']);
    },
    namesInUse () {
      let shiftTypes = this.shiftTypes;
      if (this.selectedShiftType) {
        shiftTypes = _.filter(this.shiftTypes, (st) => st.id !== this.selectedShiftType.id);
      }
      return shiftTypes.map((st) => st.name);
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredShiftTypes.length / this.pagination.itemsPerPage);
    },
    paginatedShiftTypes () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredShiftTypes.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredShiftTypes;
    },
    shiftTypes () {
      return _.cloneDeep(this.$store.state.org.shiftTypes);
    },
    shortcutsInUse () {
      let shiftTypes = this.shiftTypes;
      if (this.selectedShiftType) {
        shiftTypes = _.filter(this.shiftTypes, (st) => st.id !== this.selectedShiftType.id);
      }
      shiftTypes = _.filter(shiftTypes, (st) => !!st.styles.web.keyboardShortcut);
      return shiftTypes.map((st) => st.styles.web.keyboardShortcut);
    },
    startTimes () {
      if (!this.selectedShiftType) {
        return [];
      }
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.selectedShiftType.endTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    }
  },
  mounted () {
    this.calcPageSize();
  },
  methods: {
    add () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/createShiftType', this.selectedShiftType).then(() => {
          showStatus({
            text: this.$t('descriptions.shiftTypeSaveSuccess'),
            type: 'success'
          });
          this.closeShiftTypeDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.shiftTypeSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 60;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeDeleteConfirmationDialog () {
      this.deleteDialog.show = false;
      this.deleteDialog.shiftType = null;
    },
    closeShiftTypeDialog () {
      this.showShiftTypeDialog = false;
      this.selectedShiftType = null;
    },
    deleteShiftType (shiftType) {
      this.dispatch('org/deleteShiftType', shiftType.id).then(() => {
        showStatus({
          text: this.$t('descriptions.shiftTypeDeletionSuccess', { name: shiftType.name }),
          type: 'success'
        });
        this.$emit('close');
      }).catch((error) => {
        const status = _.get(error, 'response.status', '');
        const responseData = {
          error: _.get(error, 'response.data')
        };

        let text = '';
        if (status === ERROR_CODES.http412PreconditionFailed) {
          text = this.$t('descriptions.shiftTypeCannotDelete', { name: shiftType.name });
        } else {
          text = this.$t('descriptions.shiftTypeDeletionFail', { name: shiftType.name });
        }

        showStatus({
          text,
          type: 'error',
          responseData
        });
      }).finally(() => {
        this.closeDeleteConfirmationDialog();
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatTime (time) {
      return moment(time, 'HH:mm:ss').format('HH:mm');
    },
    getAppDisplay (item, platform) {
      const type = _.get(item, `styles.${platform}.symbolType`, '');
      let display = '';
      switch (type) {
        case 'time':
          display = `${this.formatTime(item.startTime)} - ${this.formatTime(item.endTime)}`;
          break;
        case 'text':
          display = _.get(item, `styles.${platform}.symbolValue`, '');
          break;
      }
      return display;
    },
    getTimes () {
      const times = [];
      let date = moment('00:00:00', 'HH:mm:ss');
      let end = moment('23:30:00', 'HH:mm:ss');
      while (date.isSameOrBefore(end)) {
        times.push({
          id: date.format('HH:mm:ss'),
          name: date.format('HH:mm')
        });
        date.add(SHIFT_TIME_INTERVAL, 'minutes');
      }
      return times;
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openDeleteConfirmationDialog (shiftType) {
      this.deleteDialog.shiftType = shiftType;
      this.deleteDialog.show = true;
    },
    openShiftTypeDialog (shiftType) {
      let selectedShiftType = {
        description: '',
        endTime: '',
        maxShiftCount: null,
        name: '',
        obsolete: false,
        onCall: false,
        partakeInScheduling: true,
        startTime: '',
        styles: {
          mobile: {
            annotationColor: '#5780F3',
            bgColor: '#FFE586',
            color: '#EF7708',
            symbolType: 'text',
            symbolValue: ''
          },
          web: {
            bgColor: '',
            color: '',
            keyboardShortcut: '',
            symbolType: '',
            symbolValue: ''
          }
        }
      };
      if (shiftType) {
        selectedShiftType = _.cloneDeep(shiftType);
      }
      this.selectedShiftType = selectedShiftType;
      this.showShiftTypeDialog = true;
    },
    update () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/updateShiftType', this.selectedShiftType).then(() => {
          showStatus({
            text: this.$t('descriptions.shiftTypeSaveSuccess'),
            type: 'success'
          });
          this.closeShiftTypeDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.shiftTypeSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    setDisplayFilter (field, values) {
      this.filters[field] = values;
    },
    validateMaxShiftCount () {
      // Empty string will cause an exception in the backend since the field is numeric.
      if (!this.selectedShiftType.maxShiftCount) {
        this.selectedShiftType.maxShiftCount = null;
      }
    }
  }
};
</script>

<style lang="scss">
#shiftTypes {
  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .shift-type-dial {
    right: 0px !important;
  }
  thead th span {
    font-size: 12px !important;
  }
  tr.obsolete td span {
    color: map-get($grey, 'base') !important;
  }
}
#shiftType {
  .count-rule-job-status {
    .v-select__selections {
      font-size: 14px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      input {
        position: absolute;
        left: 0px;
        width: 10px;
      }
    }
  }
  .self-schedule-rules {
    border: 1px solid map-get($grey, 'lighten-2') !important;
    .label {
      margin-top: 10px;
    }
    .v-text-field__details {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
