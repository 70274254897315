<template>
  <v-container
    class="px-10 my-3"
    fluid
  >
    <portal to="page-title">
      <v-breadcrumbs :items="[{ text: $tc('labels.general', 2), disabled: false }, { text: $t('labels.jobStatus'), disabled: false }]">
        <template v-slot:divider>
          <v-icon size="8">
            far fa-chevron-right
          </v-icon>
        </template>
      </v-breadcrumbs>
    </portal>
    <v-card
      id="jobStatus"
    >
      <v-row
        class="actions"
        no-gutters
      >
        <v-col>
          <v-text-field
            v-model.trim="filters.name"
            :append-icon="filters.name ? '' : 'fal fa-search'"
            class="name-filter pt-3 pb-1 ml-4 extra-dense-text-field d-inline-block"
            :clearable="!!filters.name"
            dense
            hide-details
            :placeholder="$t('labels.searchByName')"
            solo
          />
          <v-btn
            class="float-right mt-2 mr-4"
            color="secondary"
            @click="openJobStatusDialog()"
          >
            {{ $t('labels.addJobStatus') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        v-resize.quiet="onWindowResized"
        fixed-header
        :headers="headers"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="paginatedJobStatus"
        :items-per-page="pagination.itemsPerPage"
        must-sort
        single-select
        :sort-by.sync="sort.by"
        :sort-desc.sync="sort.desc"
      >
        <template #header.description="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #header.shortCode="{ header }">
          <span class="grey--text text--darken-3">
            {{ header.text }}
          </span>
        </template>
        <template #item="{ item, headers: tableHeaders }">
          <tr
            :class="item.obsolete ? 'obsolete' : ''"
            @mouseenter="hoveredItem = item"
            @mouseleave="hoveredItem = null"
          >
            <td
              v-for="header in tableHeaders"
              :key="header.value"
              class="text-start"
            >
              <span
                v-if="header.value === 'name'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.name"
              >
                {{ item.name }}
              </span>
              <span
                v-if="header.value === 'description'"
                class="grey--text text--darken-3 text-truncate d-block"
                :style="{ width: `${header.width}px` }"
                :title="item.description"
              >
                {{ item.description }}
              </span>
              <span
                v-if="header.value === 'shortCode'"
                class="grey--text text--darken-3 text-truncate d-block text-capitalize"
                :style="{ width: `${header.width}px` }"
                :title="item.shortCode"
              >
                {{ item.shortCode }}
              </span>
              <template v-if="header.value === 'benefited'">
                <span
                  class="d-inline-block"
                  :style="{ 'min-width': '80px' }"
                >
                  <v-icon
                    v-if="item.benefited"
                    color="success"
                    small
                  >
                    fas fa-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="grey"
                    small
                  >
                    far fa-times-circle
                  </v-icon>
                </span>
              </template>
            </td>
            <v-speed-dial
              absolute
              class="mt-6 job-status-dial"
              :value="hoveredItem && hoveredItem.id === item.id"
              right
              :direction="'left'"
              :transition="'slide-x-reverse-transition'"
            >
              <v-btn
                class="edit"
                fab
                height="30"
                width="30"
                :title="$t('labels.editJobStatus')"
                @click="openJobStatusDialog(item)"
              >
                <v-icon small>
                  fal fa-pencil
                </v-icon>
              </v-btn>
              <v-btn
                class="delete"
                fab
                height="30"
                width="30"
                :title="$t('labels.deleteJobStatus')"
                @click="openDeleteConfirmationDialog(item)"
              >
                <v-icon
                  color="error"
                  small
                >
                  fal fa-trash-alt
                </v-icon>
              </v-btn>
            </v-speed-dial>
          </tr>
        </template>
        <template #footer>
          <v-pagination
            v-model="pagination.currentPage"
            class="py-4 footer"
            color="secondary"
            :length="numberOfPages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="pagination.maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-if="showJobStatusDialog"
      persistent
      width="500px"
      :value="showJobStatusDialog"
    >
      <v-card
        id="jobStatusDialog"
        class="pa-0"
      >
        <v-card-title class="body-2 d-block mb-2">
          <span class="body-2 font-weight-medium">
            {{ selectedJobStatus.id ? $t('labels.editJobStatus') : $t('labels.addJobStatus') }}
          </span>
          <v-btn
            class="float-right mt-n1"
            :disabled="saving"
            icon
            small
            @click="closeJobStatusDialog"
          >
            <v-icon small>
              fal fa-times
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-0">
          <ValidationObserver
            v-slot="{ invalid, passes }"
          >
            <v-form class="job-status">
              <v-container class="pt-0 px-6">
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedJobStatus.name"
                      dense
                      :label="$t('labels.name')"
                      name="name"
                      outlined
                      :rules="{ required: true, max: 32, excluded: namesInUse }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <VeeTextField
                      v-model.trim="selectedJobStatus.description"
                      dense
                      :label="$t('labels.description')"
                      name="description"
                      outlined
                      rules="max:256"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <VeeTextField
                      v-model.trim="selectedJobStatus.shortCode"
                      dense
                      :label="$t('labels.shortcode')"
                      name="shortcode"
                      outlined
                      rules="required|max:4"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                  >
                    <v-switch
                      v-model="selectedJobStatus.benefited"
                      class="pt-0 mt-1 d-inline-block"
                      color="success"
                      dense
                      hide-details
                      inset
                    >
                      <template v-slot:label>
                        <span
                          class="mr-4 body-2 grey--text text--darken-3"
                          :title="$t('labels.benefited')"
                        >
                          {{ $t('labels.benefited') }}
                        </span>
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
                <v-row
                  class="my-4"
                  dense
                  no-gutters
                >
                  <v-col>
                    <div class="grey--text text--darken-3 body-2">
                      {{ $t('labels.selfScheduleRules') }}
                    </div>
                    <div class="grey--text caption">
                      {{ $t('labels.selfScheduleRulesHint') }}
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  class="mt-4"
                  dense
                >
                  <v-col
                    cols="12"
                  >
                    <v-card
                      class="grey lighten-4 self-schedule-rules"
                      :elevation="0"
                    >
                      <v-container
                        v-if="selectedJobStatus.minShiftSettings"
                        class="pb-0 pt-6"
                      >
                        <template
                          v-for="(rule, idx) in selectedJobStatus.minShiftSettings.countRules.weekend"
                        >
                          <v-row
                            :key="`count-${idx}`"
                            class=""
                            dense
                          >
                            <v-col cols="9">
                              <div class="grey--text text--darken-3 body-2 label">
                                {{ $t('labels.minShiftRule') }}
                              </div>
                            </v-col>
                            <v-col
                              class="text-right"
                              cols="3"
                            >
                              <VeeTextField
                                v-model.number="rule.count"
                                dense
                                maxlength="3"
                                :name="`minShiftCount${idx}`"
                                outlined
                                rules="numeric"
                                @input="validateMinShiftCount(idx)"
                              />
                            </v-col>
                          </v-row>
                        </template>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider />
              <v-row dense>
                <v-col
                  class="text-right pb-0 pt-4 px-8"
                  cols="12"
                >
                  <v-btn
                    class="mr-4"
                    :disabled="saving"
                    text
                    @click="closeJobStatusDialog"
                  >
                    {{ $t('labels.cancel') }}
                  </v-btn>
                  <v-btn
                    v-if="selectedJobStatus.id"
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(update)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.save') }}
                    </span>
                  </v-btn>
                  <v-btn
                    v-else
                    class="submit"
                    color="secondary"
                    :disabled="invalid || saving"
                    @click.prevent="passes(add)"
                  >
                    <v-progress-circular
                      v-if="saving"
                      color="primary lighten-2"
                      indeterminate
                      size="22"
                      width="2"
                    />
                    <span v-else>
                      {{ $t('labels.add') }}
                    </span>
                  </v-btn>
                </v-col>
                <v-spacer />
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DeleteConfirmation
      v-if="deleteDialog.show"
      action="org/checkJobStatusDependencies"
      :delete-body="$t('descriptions.continueDeleteJobStatus', { name: deleteDialog.jobStatus.name })"
      :dependencies-body="$t('descriptions.hasDependenciesJobStatus', { name: deleteDialog.jobStatus.name })"
      :item="deleteDialog.jobStatus"
      :title="$t('labels.deleteJobStatus?')"
      @close="closeDeleteConfirmationDialog"
      @del="deleteJobStatus"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import VeeTextField from '@/components/form_controls/VeeTextField';
import DeleteConfirmation from '@/components/admin/DeleteConfirmation';
import { showStatus } from '@/plugins/vue-notification';
import { ERROR_CODES } from '@/services/constants';

export default {
  components: {
    DeleteConfirmation,
    VeeTextField
  },
  data () {
    return {
      deleteDialog: {
        show: false,
        jobStatus: null
      },
      filters: {
        name: ''
      },
      hoveredItem: null,
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        maxPaginationControls: 7
      },
      saving: false,
      selectedJobStatus: null,
      showJobStatusDialog: false,
      sort: {
        by: ['name'],
        desc: [false]
      }
    };
  },
  computed: {
    filteredJobStatus () {
      const filters = [];

      if (this.filters.name) {
        filters.push((jobStatus) => jobStatus.name.toLowerCase().indexOf(this.filters.name.toLowerCase()) >= 0);
      }

      const orders = this.sort.desc.map((desc) => desc ? 'desc' : 'asc');
      let jobStatus = this.jobStatus;
      if (filters.length > 0) {
        jobStatus = _.filter(this.jobStatus, (jobStatus) => filters.reduce((matches, filter) => {
          matches &= filter(jobStatus);
          return matches;
        }, true));
      }

      return _.orderBy(jobStatus, this.sort.by, orders);
    },
    headers () {
      return [
        {
          sortable: true,
          text: this.$t('labels.name'),
          value: 'name',
          width: 225
        },
        {
          sortable: false,
          text: this.$t('labels.description'),
          value: 'description',
          width: 225
        },
        {
          sortable: false,
          text: this.$t('labels.shortcode'),
          value: 'shortCode',
          width: 100
        },
        {
          sortable: true,
          text: this.$t('labels.benefited'),
          value: 'benefited',
          width: '*'
        }
      ];
    },
    namesInUse () {
      let jobStatus = this.jobStatus;
      if (this.selectedJobStatus) {
        jobStatus = _.filter(this.jobStatus, (st) => st.id !== this.selectedJobStatus.id);
      }
      return jobStatus.map((st) => st.name);
    },
    numberOfPages () {
      if (!this.pagination.itemsPerPage) {
        return null;
      }
      return Math.ceil(this.filteredJobStatus.length / this.pagination.itemsPerPage);
    },
    paginatedJobStatus () {
      if (this.pagination.itemsPerPage) {
        const start = (this.pagination.currentPage - 1) * this.pagination.itemsPerPage;
        return this.filteredJobStatus.slice(start, start + this.pagination.itemsPerPage);
      }
      return this.filteredJobStatus;
    },
    jobStatus () {
      return _.cloneDeep(this.$store.state.org.jobStatus);
    }
  },
  mounted () {
    this.calcPageSize();
  },
  methods: {
    add () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/createJobStatus', this.selectedJobStatus).then(() => {
          showStatus({
            text: this.$t('descriptions.jobStatusSaveSuccess'),
            type: 'success'
          });
          this.closeJobStatusDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.jobStatusSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 55;
      const tableHeaderHeight = 36;
      const tdHeight = 60;
      const tableFooterHeight = 35;
      const marginAndPadding = 40;
      const tbodyHeight = window.innerHeight - (
        topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
      );

      this.pagination.itemsPerPage = Math.floor(tbodyHeight / tdHeight);
    },
    closeDeleteConfirmationDialog () {
      this.deleteDialog.show = false;
      this.deleteDialog.jobStatus = null;
    },
    closeJobStatusDialog () {
      this.showJobStatusDialog = false;
      this.selectedJobStatus = null;
    },
    deleteJobStatus (jobStatus) {
      this.dispatch('org/deleteJobStatus', jobStatus.id).then(() => {
        showStatus({
          text: this.$t('descriptions.jobStatusDeletionSuccess', { name: jobStatus.name }),
          type: 'success'
        });
        this.$emit('close');
      }).catch((error) => {
        const status = _.get(error, 'response.status', '');
        const responseData = {
          error: _.get(error, 'response.data')
        };

        let text = '';
        if (status === ERROR_CODES.http412PreconditionFailed) {
          text = this.$t('descriptions.jobStatusCannotDelete', { name: jobStatus.name });
        } else {
          text = this.$t('descriptions.jobStatusDeletionFail', { name: jobStatus.name });
        }

        showStatus({
          text,
          type: 'error',
          responseData
        });
      }).finally(() => {
        this.closeDeleteConfirmationDialog();
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    onWindowResized () {
      this.calcPageSize();
    },
    openDeleteConfirmationDialog (jobStatus) {
      this.deleteDialog.jobStatus = jobStatus;
      this.deleteDialog.show = true;
    },
    openJobStatusDialog (jobStatus) {
      let selectedJobStatus = {
        benefited: false,
        description: '',
        minShiftSettings: {},
        name: '',
        obsolete: false,
        shortCode: ''
      };
      if (jobStatus) {
        selectedJobStatus = _.cloneDeep(jobStatus);
      }
      if (!_.has(selectedJobStatus, 'minShiftSettings.countRules.weekend')) {
        _.set(selectedJobStatus, 'minShiftSettings.countRules.weekend', [
          {
            count: null
          }
        ]);
      }
      this.selectedJobStatus = selectedJobStatus;
      this.showJobStatusDialog = true;
    },
    update () {
      if (!this.saving) {
        this.saving = true;
        this.dispatch('org/updateJobStatus', this.selectedJobStatus).then(() => {
          showStatus({
            text: this.$t('descriptions.jobStatusSaveSuccess'),
            type: 'success'
          });
          this.closeJobStatusDialog();
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.jobStatusSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    validateMinShiftCount (index) {
      if (!this.selectedJobStatus.minShiftSettings.countRules.weekend[index].count) {
        this.selectedJobStatus.minShiftSettings.countRules.weekend[index].count = null;
      }
    }
  }
};
</script>

<style lang="scss">
#jobStatus {
  .actions {
    background-color: white;
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
  .footer {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .job-status-dial {
    right: 0px !important;
  }
  tr.obsolete td span {
    color: map-get($grey, 'base') !important;
  }
  thead th span {
    font-size: 12px !important;
  }
}
#jobStatusDialog {
  .count-rule-job-status {
    .v-select__selections {
      font-size: 14px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      input {
        position: absolute;
        left: 0px;
        width: 10px;
      }
    }
  }
  .self-schedule-rules {
    border: 1px solid map-get($grey, 'lighten-2') !important;
    .label {
      margin-top: 10px;
    }
    .v-text-field__details {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
